<template>
  <div class="other-delivery">
    <PageHeader title="商品出库" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex j-start">
        <div class="flex a-center">
          <div class="f14 p-right-10">买方终端</div>
          <el-select v-model="form.BuyCompanyID" remote :loading="loading" :remote-method="remoteMethod" filterable size="mini" style="width:200px" placeholder="请选择">
            <el-option
              v-for="(item,index) in BuyersTerminal"
              :key="index"
              :label="item.text"
              :value="item.IDX"
            />
          </el-select>
        </div>
        <div class="flex a-center">
          <div class="f14 p-lr-10">收货单位</div>

          <el-select v-model="form.SendCompanyID" size="mini" style="width:200px" placeholder="请选择" @change="change">
            <el-option
              v-for="(item,index) in ReceivingUnit"
              :key="index"
              :label="item.text"
              :value="item.IDX"
            />
          </el-select>
        </div>
        <!-- <div class="flex a-center"> -->
        <!-- <div class="left f14 p-lr-10">送货地址</div> -->
        <!-- <div class="left f14 min-border-right">送货地址</div>
        <div class="mid  f14 el-1 p-left-5">苏州市工业园区奥术大师大所多所大撒所多撒多撒</div>
        <div class="right f14 min-border-left cursor">更换</div>
        -->
        <!-- <el-input v-model="form.SendAddress" size="mini" />
          <el-button size="mini" @click="chioceADress">选择</el-button> -->
        <!-- v-model="form.SendAddress" -->
        <!--
            <el-select
            size="mini"
            style="width:90px"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in CompanyAddress"
              :key="index"
              :label="item.FinalAddress"
              :value="item.FinalAddress"
            />
            </el-select> -->
        <!-- </div> -->
        <div class="flex a-center">
          <div class="f14 p-lr-10"><span class="orange">*</span> 出库类型</div>
          <el-select v-model="form.StockOutType" size="mini" style="width:100px" placeholder="请选择">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="flex">
        <!-- <div class="ModelBttton-white-30 m-right-10">
          <i class="el-icon-upload2 f15 m-right-5" />
          <span>导入</span>
        </div> -->
        <div class="ModelBttton-white-30 m-right-10" @click="addGoods">
          <i class="el-icon-zoom-in f15 m-right-5" />
          <span>添加产品</span>
        </div>
        <div class="ModelBttton-white-30" @click="chuK">
          <span>出库发货</span>
        </div>
      </div>
    </div>
    <div class="flex a-center p-lr-10 m-bottom-20" style="width:100%">
      <div class="left f14 p-left-10" style="width:65px">送货地址</div>
      <el-input v-model="form.SendAddress" size="mini" style="width:50%;margin-right:20px" />
      <el-button size="mini" @click="chioceADress">选择</el-button>
    </div>
    <div class="p-lr-20">
      <el-table
        :data="form.stockOutDeliveryByArtificials"
        border
        height="calc(100vh - 390px)"
        :header-cell-style="headerCStyle"
        row-key="IDX"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column
          type="selection"
          width="42"
          align="center"
          fixed="left"
        />
        <el-table-column align="left" property="InvCode" label="物料编码" show-overflow-tooltip width="120" />
        <el-table-column align="left" property="InvName" label="物料名称" show-overflow-tooltip width="150" />
        <el-table-column align="left" property="BrandName" label="物料品牌" show-overflow-tooltip width="150" />
        <el-table-column align="left" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="left" property="Specification" label="物料规格" show-overflow-tooltip width="150" />
        <el-table-column align="left" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <el-table-column align="left" property="LotNum" label="物料批号" show-overflow-tooltip />
        <el-table-column align="left" property="LotNo" label="物料批次号" show-overflow-tooltip />
        <el-table-column align="left" property="ExpiredDate" label="有效期" show-overflow-tooltip width="120" />
        <el-table-column align="left" property="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column align="left" property="StockOutNum" label="出库数量">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.StockOutNum" /> -->
            <el-input-number v-model="scope.row.StockOutNum" style="width:80px" size="mini" :min="1" :controls="false" :max="9999" />
          </template>
        </el-table-column>
        <el-table-column align="left" property="Price" label="物料单价">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.Price" /> -->
            <el-input-number v-model="scope.row.Price" style="width:80px" size="mini" :min="1" :controls="false" :max="9999" />
          </template>
        </el-table-column>
        <el-table-column align="left" property="Subtotal" label="小计">
          <template slot-scope="scope">
            <el-input :value="scope.row.Price * scope.row.StockOutNum " disabled />
          </template>
        </el-table-column>

        <el-table-column align="left" property="DINum" label="DI码" show-overflow-tooltip />
        <el-table-column align="left" property="StorageConditionName" label="运输条件" show-overflow-tooltip />
        <!-- <el-table-column align="center" property="StockCode" label="库存编号" />
        <el-table-column align="center" property="MaterialStockSellId" label="物料库位ID" /> -->
        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <!-- slot-scope="score" -->
          <template slot-scope="score">
            <el-link :underline="false" @click="removeDom(score.row)">移除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="remake_view flex j-between m-top-20">
        <div id="remake_view_left" class="remake_view_left f14 flex">
          <div style="width:80px;line-height:50px" class="center">备注</div>
          <el-input v-model="form.Remarks" />
        </div>
        <el-button type="primary" icon="el-icon-printer" @click="printI">打印</el-button>
      </div>
    </div>
    <!-- <div class="pagination-container">
      <el-pagination
        :ref="paginationName"
        :total="tableTotalSize"
        :pager-count="pagerCount"
        :page-size="tablePageSize"
        :background="tableBackground"
        layout="prev, pager, next, jumper"
        @current-change="handleCurrentChange($event)"
      />
    </div> -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">添加商品</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
      </div>
      <div class="search searchView flex j-around m-tb-20">
        <div class="flex m-right-20" style="flex: 1">
          <el-input
            v-model="formSearch.KeyWords"
            size="medium"
            placeholder="请输入内容"
            class="rule-input-edit"
          >
            <template slot="prepend">
              <div class="pointer" @click="() => visibl1e = true">
                <i class="el-icon-s-operation" />
                <span>高级搜索</span>
              </div>
            </template>
            <div
              slot="append"
              @click="searchAd"
            >
              搜 索
            </div>
          </el-input>
        </div>
        <el-button style="font-size:14px" size="mini" icon="el-icon-folder-add" round @click="addGoodS">批量添加</el-button>
      </div>
      <el-table
        ref="testTable"
        :data="tableData"
        :header-cell-style="headerCStyle"
        border
        max-height="500px"
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column
          type="selection"
          width="40"
          :selectable="checkboxInit"
          fixed="left"
        />
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" property="BrandName" label="物料品牌" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialTypeName" label="物料类别" show-overflow-tooltip />
        <el-table-column align="center" property="Specification" label="物料规格" show-overflow-tooltip />
        <el-table-column align="center" property="StockUnitN" label="物料单位" show-overflow-tooltip />
        <el-table-column align="center" property="LotNum" label="物料批号" show-overflow-tooltip />
        <el-table-column align="center" property="LotNum" label="是否盘点中" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.isCorrecting === 1 ? '是':'否' }}
          </template>
        </el-table-column>
        <el-table-column align="center" property="ExpiredDate" label="效期" show-overflow-tooltip />
        <el-table-column align="center" property="StockNum" label="可用数量" show-overflow-tooltip />
        <el-table-column align="center" property="a" fixed="right" label="操作" width="50">
          <template v-if="!scope.row.disabled && scope.row.isCorrecting !== 1" slot-scope="scope">
            <el-link :underline="false" @click="addGoodOne(scope.row)">添加</el-link>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container p-bottom-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground1"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange1($event)"
        />
      </div>
    </el-dialog>
    <!-- 选择订单地址 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="showAddress" custom-class="dialog_view1" :show-close="false" width="600px">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">选择地址</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showAddress = false" />
      </div>
      <div class="m-tb-10 t-right">
        <el-button class="MoedelBotton-blue-sm" @click="() => showSetAddress = true">新建地址</el-button>
      </div>
      <!-- 地址 start-->
      <div v-if="addressList.length" v-loading="isAddressLoading" class="address_view_set flex column p-bottom-20">
        <div v-for="(item,index) in addressList" :key="index" style="width:100%;box-sizing: border-box" class="item flex a-center j-between p-left-10">
          <div style="flex:1">
            {{ item.Province + item.City + item.Area + `${item.Street ? item.Street :''} `+ item.Address + item.ContactName + item.ContactTel }}
          </div>
          <div style="min-width:50px" class="flex">
            <el-button class="ModelBttton1-white-24" @click="slectAddressInfo(item)">选择</el-button>
          </div>
        </div>
      </div>
      <div v-else class="m-bottom-20">
        暂无地址数据
      </div>
      <!-- 地址 end-->
    </el-dialog>
    <!-- 选择订单地址 end -->
    <!-- 新建地址 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="showSetAddress" custom-class="dialog_view" :show-close="false" width="400px!important">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">新建地址</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetAddress = false" />
      </div>
      <div class="m-top-20" />
      <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="80px" class="demo-ruleForm">
        <el-form-item label="上门自提" prop="name">
          <el-checkbox v-model="ruleForm1.TakeSelf">备选项</el-checkbox>
        </el-form-item>
        <el-form-item label="省" prop="Province">
          <el-cascader
            v-model="ruleForm1.Province"
            size="mini"
            :options="Province"
            style="width:100%"
            @change="handleChange"
          />
          <!-- <el-input v-model="ruleForm1.Province" size="mini" /> -->
        </el-form-item>
        <el-form-item label="市" prop="City">
          <el-cascader
            v-model="ruleForm1.City"
            size="mini"
            :options="City"
            style="width:100%"
            @change="handleChange1"
          />
          <!-- <el-input v-model="ruleForm1.City" size="mini" /> -->
        </el-form-item>
        <el-form-item label="区" prop="Area">
          <el-cascader
            v-model="ruleForm1.Area"
            size="mini"
            :options="Area"
            style="width:100%"
          />
          <!-- <el-input v-model="ruleForm1.Area" size="mini" /> -->
        </el-form-item>
        <!-- <el-form-item label="街道" prop="Street">
          <el-input v-model="ruleForm1.Street" size="mini" />
        </el-form-item> -->
        <el-form-item label="地址" prop="Address">
          <el-input v-model="ruleForm1.Address" size="mini" />
        </el-form-item>
        <el-form-item label="收货部门" prop="DeptName">
          <el-input v-model="ruleForm1.DeptName" size="mini" />
        </el-form-item>
        <el-form-item label="联系人" prop="ContactName">
          <el-input v-model="ruleForm1.ContactName" size="mini" />
        </el-form-item>
        <el-form-item label="联系电话" prop="ContactTel">
          <el-input v-model="ruleForm1.ContactTel" size="mini" />
        </el-form-item>
        <el-form-item label="上传附件" prop="Filepath">
          <input id="file" type="file" name="file" multiple="multiple" @change="handleFileChange">
        </el-form-item>
        <el-form-item class="caozuo p-tb-20 t_line_s">
          <el-button @click="() => showSetAddress = false">取消</el-button>
          <el-button type="primary" @click="submitAddress('ruleForm1')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新建地址 end-->
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="visibl1e" @cancel="(e) => visibl1e = e" @searchAd="searchAd" />
  </div>
</template>
<script>
import Index from '@/minxin/management/other-delivery'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/purchase.scss";
@import "@/style/search.scss";
.dialog_view{
    position: relative;
}
 .PageHeader1{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top:0;
    display: flex;
    color:   #006fda;
    &::after{
        content: '';
        width: 3px;
        height:50%;
        background-color:  #006fda;
        position: absolute;
        left: 0;
        top: 25%;
    }
 }
</style>

/* eslint-disable no-unreachable */
import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import { regionData, CodeToText } from 'element-china-area-data' // CodeToText
const Index = {
  name: 'otherDelivery',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      loading: false,
      paginationName1: 'tablePagination1',
      tableTotalSize1: 0,
      pagerCount1: 5,
      tablePageSize1: 5,
      tableBackground1: true,
      adSearchForm: [
        { name: '物料编码', value: 'MaterialInvCode' },
        { name: '物料品牌', value: 'BrandName' },
        { name: '物料名称', value: 'MaterialName' },
        { name: '物料类型', value: 'MaterialType' },
        { name: '物料DI码', value: 'DINum' }
      ],
      formSearch: {
        KeyWords: '',
        MaterialInvCode: '', // 物料编码
        BrandName: '', // 物料品牌
        MaterialName: '', // 物料名称
        MaterialType: '', // 物料类型
        DINum: '', // 物料DI码
        PageIndex: '',
        PageSize: ''
      },
      formSearch1: {
        KeyWords: '',
        MaterialInvCode: '', // 物料编码
        BrandName: '', // 物料品牌
        MaterialName: '', // 物料名称
        MaterialType: '', // 物料类型
        DINum: '', // 物料DI码
        PageIndex: '',
        PageSize: ''
      },
      visibl1e: false,
      visible: false,
      value: '',
      // 出库单类别：0:来源出库单，1：盘点出库，2：退回出库，3：借出出库，4：其他出库
      options: [{
        value: 1,
        label: '盘点出库'
      }, {
        value: 2,
        label: '退货出库'
      }, {
        value: 3,
        label: '借出出库'
      }, {
        value: 4,
        label: '其他出库'
      }],
      //  出库发货 提交数据
      form: {
        BuyCompanyID: '', // 售达方
        SendCompanyID: '', // 送达方
        SendAddress: '', // 送达地址
        Remarks: '', // 备注
        StockOutType: '', // 出库单类型
        stockOutDeliveryByArtificials: [] // 物料列表
      },
      // 送货地址
      CompanyAddress: [],
      // 买方终端
      BuyersTerminal: [],
      // 收货单位
      ReceivingUnit: [],
      selects: [],
      flag: false,
      // 地址
      showAddress: false,
      addressList: [],
      isAddressLoading: false,
      showSetAddress: false,
      ruleForm1: {},
      Province: [],
      City: [],
      Area: [],
      rules1: {
        Province: [
          { required: true, message: '请输入省', trigger: 'blur' }
        ],
        City: [
          { required: true, message: '请输入市', trigger: 'blur' }
        ],
        Area: [
          { required: true, message: '请输入区', trigger: 'blur' }
        ],
        Address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        ContactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        ContactTel: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.GetCompanyNameQueryListJson()
    regionData.map(item => {
      this.Province.push({
        label: item.label,
        value: item.value
      })
    })
  },
  watch: {
    selects(a) {
      console.log('已选', a)
    }
  },
  methods: {
    // 收货地址 选择
    addressShow(type) {
      this.showAddress = true
      this.ruleForm1.AddrType = 10
      this.$api.GetUseraddressList({
        CompanyID: this.form.SendCompanyID, // this.createForm.isKe === 1 ? this.form.SendCompanyID :
        AddrType: 10
      }).then(res => {
        this.addressList = res.Message
      })
    },
    // 新增地址/ 编辑
    submitAddress(formName) {
      this.ruleForm1.Province = CodeToText[this.ruleForm1.Province[0]]
      this.ruleForm1.City = CodeToText[this.ruleForm1.City[0]]
      this.ruleForm1.Area = CodeToText[this.ruleForm1.Area[0]]
      this.ruleForm1.CompanyID = this.form.SendCompanyID
      console.log(this.ruleForm1)

      // if (this.form.isKe === 0) {
      //   this.ruleForm1.CompanyID = 0
      // }
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        // this.ruleForm1.TakeSelf ? this.ruleForm1.TakeSelf = 1 : this.ruleForm1.TakeSelf = 0
        // 保存地址
        this.$api.SaveUserAddress(this.ruleForm1).then(res => {
          console.log(res)
          this.showSetAddress = false
          if (res.RetCode === '0') {
            this.$refs[formName].resetFields()
            this.addressShow()
            this.ruleForm1.CompanyID = ''
            return this.$message.success('保存成功')
          }
          this.ruleForm1.CompanyID = ''
          return this.$message.error('保存失败')
        })
      })
    },
    chioceADress() {
      if (!this.form.SendCompanyID) return this.$message.info('请先选择收货单位')
      this.change()
    },
    // 选择
    slectAddressInfo(item) {
      console.log(item)
      this.form.SendAddress = item.FinalAddress
      // if (this.AddressType === 10) {
      //   this.createForm.address = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      //   this.createForm.OrderAddressID = item.IDX
      // }
      // if (this.AddressType === 20) {
      //   this.createForm.card = item.Province + item.City + item.Area + item.Street + item.Address + item.ContactName + item.ContactTel
      //   this.createForm.InvoiceSendID = item.IDX
      // }
      this.showAddress = false
    },
    handleChange(value) {
      if (!value) return
      this.City = []
      const City = []
      Object.assign(City, regionData.filter(item => item.value === value[0]))
      City[0].children.map(item => {
        this.City.push({
          label: item.label,
          value: item.value
        })
      })
      // this.ruleForm1.Province = CodeToText[value[0]]

      // console.log(this.City)
    },
    handleChange1(value) {
      if (!value) return
      this.Area = []
      const City = regionData.filter(item => item.value === this.ruleForm1.Province[0])
      console.log(City)
      const Area = City[0].children.filter(item => item.value === value[0])
      console.log(Area)
      if (Area[0].children) {
        Area[0].children.map(item => {
          this.Area.push({
            label: item.label,
            value: item.value
          })
        })
      }
      // this.ruleForm1.City = CodeToText[value[0]]
    },
    handleFileChange(e) {
      const _this = this
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        _this.$api.OtherInStockUploadAccessory({
          FileExtName: '.' + file.type.split('/')[1],
          Base64String: e.target.result
        }).then(res => {
          _this.ruleForm1.Filepath = res.Message
        })
      }
    },
    async handleCurrentChange1(page) {
      //   this.catchAsync(
      // { Message, Total }
      const { Message, Total, RetCode } = await this.getTableDataAsync(page, this.tablePageSize)
      // this.tableLoading = false
      if (RetCode === '0' && Message.length > 0) {
        this.tableTotalSize1 = Total
        this.tableData = Message
      }
    },
    // 收货单位改变
    change() {
      console.log(this.form.SendCompanyID)
      this.getQueryCompanyAddress(this.form.SendCompanyID) // 1974
    },
    // 批量添加
    addGoodS() {
      if (!this.selects.length) return this.$message.warning('请选择你要出库的物料')
      this.form.stockOutDeliveryByArtificials = this.selects
      this.form.stockOutDeliveryByArtificials.map(item => {
        this.$set(item, 'StockOutNum')
      })
      this.visibl1e = false
      this.visible = false
      this.selects = []
    },
    // 单个添加
    addGoodOne(row) {
      this.form.stockOutDeliveryByArtificials.push(row)
      this.form.stockOutDeliveryByArtificials.map(item => {
        this.$set(item, 'StockOutNum')
      })
      this.visibl1e = false
      this.visible = false
    },
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    async  searchAd() {
      // this.formSearch.PageIndex = 1
      // this.formSearch.PageSize = this.tablePageSize1
      // console.log(' this.formSearch', this.formSearch)
      // const response = await this.$api.QueryMaterials(this.formSearch)
      // this.tableData = response.Message
      // this.tableTotalSize = response.Total
      this.getTableDataAsync()
      // this.formSearch.KeyWords = null
      this.visibl1e = false
    },
    // 添加商品弹出框
    addGoods() {
      this.formSearch = this.formSearch1
      this.getTableDataAsync()
      this.visible = true
    },
    // 获取物料列表
    async getTableDataAsync(page, PageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize1
      const response = await this.$api.QueryMaterials(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize1 = response.Total
      this.tableData.map(item => {
        if (item.isCorrecting === 1) {
          this.$nextTick(() => {
            this.$set(item, 'disabled', true)
          })
        }
      })
      if (this.form.stockOutDeliveryByArtificials.length > 0) {
        this.tableData.map(item => {
          this.form.stockOutDeliveryByArtificials.map(item1 => {
            if (item.IDX === item1.IDX) {
              this.$nextTick(() => {
                this.$refs.testTable.toggleRowSelection(item, true)
                this.$set(item, 'disabled', true)
              })
            }
          })
        })
      }
      console.log(response)
      return response
    },
    checkboxInit(row, index) {
      if (row.disabled) {
        return 0// 不可勾选
      } else {
        return 1// 可勾选
      }
    },
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.GetCompanyNameQueryListJson(query)
        }, 200)
      } else {
        this.GetCompanyNameQueryListJson()
      }
    },
    // GetCompanyNameQueryListJson 买方终端 收货单位地址 companyType
    async GetCompanyNameQueryListJson(companyName) {
      const response = await this.$api.GetCompanyNameQueryListJson({
        companyType: 0,
        companyName: companyName || '',
        PageSize: 100,
        PageIndex: 1
      })
      this.BuyersTerminal = response.Message
      this.ReceivingUnit = response.Message
      console.log(response)
    },
    // 查询送货地址
    async getQueryCompanyAddress(CompanyID) {
      // const response = await this.$api.QueryCompanyAddress({ CompanyID })
      const response = await this.$api.GetUseraddressList({ CompanyID, AddrType: 10 })
      this.showAddress = true
      this.addressList = response.Message
      // this.CompanyAddress = response.Message
    },
    // 删除某个节点
    removeDom(row) {
      console.log('要移除的row', row)
      this.form.stockOutDeliveryByArtificials.forEach((item, index) => {
        if (item.IDX === row.IDX) {
          this.form.stockOutDeliveryByArtificials.splice(index, 1)
          return this.$message.success('移除成功')
        }
      })
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    // 打印
    printTable() {
      var title = ''
      var divElements = ''
      // console.log(divElements)
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      const arr12 = [{
        label: '物料编码',
        prop: 'InvCode'
      }, {
        label: '物料名称',
        prop: 'InvName'
      }, {
        label: '品牌',
        prop: 'BrandName'
      }, {
        label: '物料类型',
        prop: 'MaterialSubTypeName'
      }, {
        label: '规格',
        prop: 'Specification'
      }, {
        label: '单位',
        prop: 'StockUnitN'
      }, {
        label: '物料批号',
        prop: 'LotNum'
      }, {
        label: '有效期',
        prop: 'ExpiredDate'
      }, {
        label: '物料批次号',
        prop: 'LotNo'
      }, {
        label: '可用数量',
        prop: 'StockNum'
      }, {
        label: '出库数量',
        prop: 'StockOutNum'
      }, {
        label: '物料单价',
        prop: 'Price'
      }, {
        label: '小计',
        prop: 'StockOutNum'
      }]
      arr12.map(item => {
        num += 1
        str_tr += `
                <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
            `
      })
      arr12.map((item1, index) => {
        // console.log(index)
        this.form.stockOutDeliveryByArtificials.map((item, index1) => {
          str_td += `<td  id="testTd" style="text-align:center;border:solid #e5e5e5 1px;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
        })
      })
      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      // console.log('str_td', testTD)
      // console.log('num', num)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      // console.log(arr)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
              <table border="1" class='table-0' id="dasasasdsad" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:none;width:100%">
              <tr>
                ${str_tr}
              </tr>
              ${str}
            </table>
          `
      // console.log(table_)
      divElements = `<div class='div-0'> ${table_}</div>`
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
           html, body {
             font-size: 12px;
             margin: 0;
           }
          .p-lr-20 {padding: 0 20px; }
          .m-bottom-20 {margin-bottom: 20px; }
          .weight { font-weight: 700 }
          .f14 { font-size:12px}
          .f20 {font-size:12px}
          .flex {display:flex}
          .j-center {justify-content: center;}
          .column { flex-direction: column;}
          .a-center {align-items: center;}
          .f-warp { flex-wrap: wrap; }
          .j-start {justify-content: flex-start;}
          .m-tb-10 {margin: 10px 0;}
          .m-tb-20 {margin: 20px 0;}
          .j-between {justify-content: space-between;}
          .m-top-10 {margin-top: 10px }
          .element.style {width: auto; }
          .table_info {
            max-width: 1000px;
            margin: 0 auto;
          }
          table{table-layout: fixed;}
          td{word-break: break-all; word-wrap:break-word;}
          .div-0 {page-break-after: always}
          </style>`
      )
      printWindow.document.write('</head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
          window.onload = function() {
            class Test {
              constructor() {
                this.windowHeight = null // 窗口高度
                this.pageIndex = 0 // 当前页
                this.height_print = '' // 初始高度
                this.max_height = null // 最大高度
                this.tbody_tr = '' // 获取表格子集
                this.splice_arr = [] // 被删掉的元素
                this.header_tr = null // 表头的tr 不改动
                this.table_main = null // 表格 父级
                this.table_main_height = 0 // 表格高度
                this.bottom_veiw_height = 0 // 表底高度
                this.top_veiw_height = 0 // 表头高度
                this.table_footer_height = 0 //  // 表格合计高度
                this.top_veiw_html = null // 表头的内容、
                this.bottom_veiw_html = null // 表底的内容
                this.title_view_html = null //  顶部标题dom
                this.table_footer_html_0 = null // 表格合计内容
                this.table_footer_html_1 = null // 表格合计内容
              }
              init() {
                this.windowHeight = window.screen.height
                 this.max_height =  Math.round(this.windowHeight  / 2)
                 console.log('this.max_height ',this.max_height )
                const t_b_view = document.getElementsByClassName('adsadasd1')
                this.table_main_height = document.getElementById('dasasasdsad').offsetHeight // 表格高度
                // this.table_main = document.getElementById('dasasasdsad') // 表格 父级
                this.table_main = document.getElementsByClassName('table-0')[ this.pageIndex] // 表格 父级
                // document.getElementsByClassName('div-0')[this.pageIndex].style.height =  this.max_height + 'px'
                // console.log( this.table_main.childNodes)
                this.tbody_tr = this.table_main.childNodes[1].children // 获取表格子集
                this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
                this.title_view_html = document.getElementsByClassName('header')[0].innerHTML // 顶部标题dom
                this.table_footer_height = (document.getElementsByClassName('table_footer')[0].offsetHeight) * 2 // 表格合计高度
                this.table_footer_html_0 = document.getElementsByClassName('table_footer')[0].innerHTML // 表格合计内容
                this.table_footer_html_1 = document.getElementsByClassName('table_footer')[1].innerHTML // 表格合计内容
                for (let i = 0; i < t_b_view.length; i++) {
                  this.top_veiw_height = t_b_view[0].offsetHeight
                  this.bottom_veiw_height = t_b_view[1].offsetHeight
                  this.top_veiw_html = t_b_view[0].innerHTML
                  this.bottom_veiw_html = t_b_view[1].innerHTML
                }
                this.heightA()
                this.isSet()
    
              }
          
              // 是否换页
              isSet() {
                if (this.height_print <= this.max_height) return // 不需要换页
                if (this.height_print > this.max_height) {
                   this.setData()
                }
              }
              // 处理换页的数据
              setData() {
                const arr = []
                for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
                  if (this.height_print > this.max_height) {
                    // 将一个节点存起来
                    arr.push(this.tbody_tr[i])
                    // 删除一个节点
                    this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                    this.heightA()
                  }
                }
                this.tbody_tr = arr
                this.initDocument()
              }
          
              // 计算高度 当前页高度
              heightA() {
                   
                  this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
                  // document.getElementsByClassName('div-0')[0].style.background = 'red'
                  // console.log("最大高度",this.max_height)
                  // console.log('目前高度', this.height_print)
                // return this.height_print
              }
              // 为页面添加新的页
              initDocument() {
                this.pageIndex += 1
                let new_tr_td = ''
                for (let i = 0; i < this.tbody_tr.length; i++) {
                  new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
                }
          
                // 新的表格
                const table_ = "<table border='1' class='table-0' id='dasasasdsad' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:none;width:100%'>" + this.header_tr + new_tr_td + '</table>'
                const dom_new = "<div class='div-0'><div class='header weight f20 flex j-center column a-center m-tb-20'>" + this.title_view_html + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.top_veiw_html + '</div>' + table_ + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_0 + '</div>' + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_1 + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.bottom_veiw_html + '</div> </div> '
                //   window.document.write(dom_new)
                const conentDiv = document.createElement('div')
                conentDiv.innerHTML = dom_new
                document.body.appendChild(conentDiv)
                this.init()
              }
            }
            setTimeout(() => {
                  const test = new Test()
            test.init()
            }, 60)
          
          }
          </script>`)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    // 打印
    printI() {
      // form.stockOutDeliveryByArtificials
      this.printTable()
    },
    // 出库发货
    chuK() {
      console.log(this.form)
      /*
      BuyCompanyID: ""
      Remarks: ""
      SendAddress: ""
      SendCompanyID: ""
      StockOutType: ""
      stockOutDeliveryByArtificials: Array(10) */
      if (this.form.stockOutDeliveryByArtificials.length <= 0) return this.$message.warning('请选择物料')
      const arr = this.form.stockOutDeliveryByArtificials.filter(item => item.StockOutNum)
      if (arr.length <= 0) return this.$message.warning('出库数量不能为空')
      const arr1 = this.form.stockOutDeliveryByArtificials.filter(item => item.Price)
      if (arr1.length <= 0) return this.$message.warning('出库单价不能为空')
      this.form.StockOutType += ''
      // if (!this.form.BuyCompanyID) return this.$message.error('买方终端不能为空')
      if (!this.form.Remarks) return this.$message.error('备注不能为空')
      // if (!this.form.SendAddress) return this.$message.error('送货地址不能为空')
      // if (!this.form.SendCompanyID) return this.$message.error('收货单位不能为空')
      if (!this.form.StockOutType) return this.$message.warning('出库类型不能为空')

      if (this.flag) return
      this.flag = true
      this.$api.StockOutDeliveryByArtificial(this.form).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.$router.push({
            name: 'DeliveryDetails',
            params: { StockOutID: res.Message, DeliveryId: 0, isCache: 6 }
          })
          // const fromPathIndex = this.$store.state.index.tags.findIndex(item => item.fullPath === from.fullPath)
          // store.state.index.tags.splice(fromPathIndex, 1)
          // store.dispatch('index/setTags', store.state.index.tags)

          // const IndexAitem = this.$store.state.index.cachedViews.findIndex(item1 => item1 === 'otherDelivery')
          // this.$store.state.index.cachedViews.splice(IndexAitem, 1)
          // this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
          // this.$router.push({
          //   name: 'delivery-fwarehouse',
          //   params: { StockOutType: this.form.StockOutType,
          //     StockOutId: res.Message }
          // })
          this.flag = false
          return
        }
        this.flag = false
        return this.$message.error('请求成功')
      }).catch(() => {
        this.flag = false
        return this.$message.error('请求失败')
      })
    }
  }
}

export default Index
